<template>
  <a-drawer
    :title="title"
    placement="right"
    :closable="false"
    :visible="visible"
    :width="width"
    @close="onClose"
  >
    <component :is="drawerType" :is-drawer="true"></component>
  </a-drawer>
</template>
<script>
import UserCreate from '../../views/users/create.vue';
import ProductCreate from '../../views/products/create.vue';
import CategoryCreate from '../../views/categories/create.vue';
import VendorCreate from '../../views/vendors/create.vue';
import WarehouseCreate from '../../views/warehouses/create.vue';
import MeasurementCreate from '../../views/measurements/create.vue';
import AttributeCreate from '../../views/attributes/create.vue';

export default {
  components: {
    UserCreate,
    ProductCreate,
    CategoryCreate,
    VendorCreate,
    WarehouseCreate,
    MeasurementCreate,
    AttributeCreate,
  },
  data() {
    return {
      width: 800,
    };
  },
  methods: {
    onClose() {
      this.$store.dispatch('drawer/clearFormFieldsOnClose', true);
    },
  },
  computed: {
    visible() {
      return this.$store.getters['drawer/getVisible'];
    },
    drawerType() {
      return this.$store.getters['drawer/getDrawerType'];
    },
    title() {
      return this.$store.getters['drawer/getDrawerTitle'];
    },
  },
};
</script>
