<template>
  <a-form :form="form" @submit="onSubmit">
    <a-form-item label="Наименование склада">
      <a-input v-decorator="[
          'Name',
          { rules: [{ required: true, message: 'Пожалуйста заполните Наименование товара' }] },
        ]"/>
    </a-form-item>

    <a-form-item label="Назначенные пользователи">
      <a-select
        show-search
        :value="searchUserValue"
        @change="selectUser"
        placeholder="Введите Имя пользователя"
        style="width: 400px"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchUser"
      >
        <a-select-option v-for="(user, index) in users" :key="index">
          {{ user.Email }} ({{ user.FirstName }})
        </a-select-option>
      </a-select>
      <a-button :disabled="!searchUser" class="editable-add-btn" @click="handleAdd">
        Добавить пользователя
      </a-button>
    </a-form-item>

    <a-form-item label="Привязанные пользователи">
      <a-table bordered :data-source="selectedUsers" :columns="columns">
        <span slot="name" slot-scope="item">
          {{ item.FirstName }}
        </span>
        <template slot="action" slot-scope="item">
          <a-popconfirm
            title="Sure to delete?"
            @confirm="() => onDelete(item)"
          >
            <a href="javascript:;">Delete</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-form-item>

    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" :loading="loading" @click="onSubmit">
        Сохранить
      </a-button>
      <a-button style="margin-left: 10px;">
        <router-link :to="{ name: 'warehouses' }">Отмена</router-link>
      </a-button>
    </a-form-model-item>
  </a-form>
</template>
<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'warehouse' });
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      searchUserValue: undefined,
      searchingUser: [],
      loading: false,
      searching: false,
      users: [],
      formData: [],
      selectedUsers: [],
      columns: [
        {
          title: 'Имя',
          dataIndex: 'FirstName',
          width: '30%',
          scopedSlots: { customRender: 'user' },
        },
        {
          title: 'Фамилия',
          dataIndex: 'LastName',
          width: '30%',
          scopedSlots: { customRender: 'user' },
        },
        {
          title: 'Электронная почта',
          dataIndex: 'Email',
          width: '30%',
          scopedSlots: { customRender: 'user' },
        },
        {
          title: '',
          dataIndex: '',
          width: '30%',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      const users = this.selectedUsers.map((user) => user.Id);

      this.form.validateFields((err, values) => {
        values.Users = users;
        if (!err) {
          this.loading = true;
          this.$api.createWarehouse(values, () => {
            this.$store.dispatch('drawer/clearFormFieldsOnClose', true);
          });
        }
      });
    },
    searchUser(value) {
      if (value.length > 1) {
        this.searching = true;
        const filters = {
          FirstName: value,
        };
        const query = Object.keys(filters)
          .map((key) => filters[key] && `filter[${key}]=${filters[key]}`)
          .join('&');
        this.$api.filterUsers(query, (response) => {
          const { data } = response.data;
          this.searching = false;
          this.users = data;
          this.searchUserValue = undefined;
        }, () => {
          this.searching = false;
        });
      }
    },
    selectUser(value) {
      this.searchUserValue = value;
    },
    handleAdd() {
      const user = this.users[this.searchUserValue];
      const exists = this.selectedUsers.find((x) => x.Id === user.Id);

      if (!exists) {
        this.selectedUsers.push(user);
      } else {
        this.searchUserValue = '';
      }
    },
    onDelete(item) {
      const result = this.selectedUsers
        .map((element, index) => {
          const isElement = element.Email === item.Email;
          return isElement ? index : false;
        })
        .filter(Number.isInteger);
      this.selectedUsers.splice(result[0], 1);
    },
  },
};
</script>
