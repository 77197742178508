<template>
  <MainLayout>
    <div style="background: #ECECEC; padding: 30px">
      <h3>Добро пожаловать</h3>
    </div>
  </MainLayout>
</template>
<script>
import MainLayout from '../layouts/MainLayout.vue';

export default {
  components: { MainLayout },
};
</script>
