import Vue from 'vue';
import * as AntComponents from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'ant-design-vue/dist/antd.css';
import api from './api';

Vue.use(api);
Vue.config.productionTip = false;
Vue.use(AntComponents);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
