import axios from 'axios';
import Vue from 'vue';
import store from './store';
import router from './router';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false,
});

const { getters, dispatch } = store;

export default {
  install(vue) {
    vue.prototype.$api = {
      async axiosPost(url, body, onSuccess, onError) {
        await api.post(url, body, {
          crossDomain: true,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosDelete(url, body, onSuccess, onError) {
        await api.delete(url, {
          crossDomain: true,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosPut(url, body, onSuccess, onError) {
        await api.put(url, body, {
          crossDomain: true,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosPostFormData(url, body, onSuccess, onError) {
        await api.post(url, body, {
          headers: this.getHeaders([
            { key: 'Content-Type', value: 'multipart/form-data' },
          ]),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      async axiosGet(url, body, onSuccess, onError) {
        await api.get(url, {
          params: body,
          headers: this.getHeaders(),
        })
          .then((response) => {
            onSuccess(response);
          })
          .catch((catchError) => {
            if (typeof onError === 'function') {
              onError(catchError.response);
            }
          });
      },
      getHeaders(replaceHeaders) {
        const headers = {
          Accept: 'application/json',
          ContentType: 'application/json',
        };

        if (getters['user/isGuest'] === false) {
          const accessToken = getters['user/accessToken'];
          headers.Authorization = `Bearer ${accessToken}`;
        }

        if (replaceHeaders) {
          replaceHeaders.forEach((replace) => {
            headers[replace.key] = replace.value;
          });
        }

        return headers;
      },

      login(body, onSuccess, onError) {
        return this.axiosPost('/auth/login', body, onSuccess, onError);
      },
      register(body, onSuccess, onError) {
        return this.axiosPost('/auth/register', body, onSuccess, onError);
      },

      // Users
      getUsers(body, onSuccess, onError) {
        return this.axiosGet('/users', body, onSuccess, onError);
      },
      filterUsers(query, onSuccess, onError) {
        return this.axiosGet(`/users?${query}`, false, onSuccess, onError);
      },
      getUsersList(body, onSuccess, onError) {
        return this.axiosGet('/users/list', body, onSuccess, onError);
      },
      getRoles(body, onSuccess, onError) {
        return this.axiosGet('/users/roles', body, onSuccess, onError);
      },
      getUser({ userId }, onSuccess, onError) {
        return this.axiosGet(`/users/${userId}`, null, onSuccess, onError);
      },
      createUser(body, onSuccess, onError) {
        return this.axiosPost('/users', body, onSuccess, onError);
      },
      saveUser({ userId }, body, onSuccess, onError) {
        return this.axiosPut(`/users/${userId}`, body, onSuccess, onError);
      },
      deleteUser({ Id }, onSuccess, onError) {
        return this.axiosDelete(`/users/${Id}`, null, onSuccess, onError);
      },

      // Products
      getProducts(body, onSuccess, onError) {
        return this.axiosGet('/products', body, onSuccess, onError);
      },
      filterProducts(query, onSuccess, onError) {
        return this.axiosGet(`/products?${query}`, false, onSuccess, onError);
      },
      getProduct({ productId }, onSuccess, onError) {
        return this.axiosGet(`/products/${productId}`, null, onSuccess, onError);
      },
      createProduct(body, onSuccess, onError) {
        return this.axiosPost('/products', body, onSuccess, onError);
      },
      saveProduct({ productId }, body, onSuccess, onError) {
        return this.axiosPut(`/products/${productId}`, body, onSuccess, onError);
      },
      storageQuery(query, onSuccess, onError) {
        return this.axiosGet(`/products?${query}`, false, onSuccess, onError);
      },
      deleteProduct({ Id }, onSuccess, onError) {
        return this.axiosDelete(`/products/${Id}`, null, onSuccess, onError);
      },

      // Warehouses
      getWarehouses(body, onSuccess, onError) {
        return this.axiosGet('/warehouse', body, onSuccess, onError);
      },
      getWarehousesList(body, onSuccess, onError) {
        return this.axiosGet('/warehouse/list', body, onSuccess, onError);
      },
      getWarehouse({ warehouseId }, onSuccess, onError) {
        return this.axiosGet(`/warehouse/${warehouseId}`, null, onSuccess, onError);
      },
      createWarehouse(body, onSuccess, onError) {
        return this.axiosPost('/warehouse', body, onSuccess, onError);
      },
      saveWarehouse({ warehouseId }, body, onSuccess, onError) {
        return this.axiosPut(`/warehouse/${warehouseId}`, body, onSuccess, onError);
      },
      deleteWarehouse({ Id }, onSuccess, onError) {
        return this.axiosDelete(`/warehouse/${Id}`, null, onSuccess, onError);
      },

      // Vendors
      getVendors(body, onSuccess, onError) {
        return this.axiosGet('/vendors', body, onSuccess, onError);
      },
      getVendor({ vendorId }, onSuccess, onError) {
        return this.axiosGet(`/vendors/${vendorId}`, null, onSuccess, onError);
      },
      createVendor(body, onSuccess, onError) {
        return this.axiosPost('/vendors', body, onSuccess, onError);
      },
      saveVendor({ vendorId }, body, onSuccess, onError) {
        return this.axiosPut(`/vendors/${vendorId}`, body, onSuccess, onError);
      },
      deleteVendor({ Id }, onSuccess, onError) {
        return this.axiosDelete(`/vendors/${Id}`, null, onSuccess, onError);
      },

      getCategoriesList(body, onSuccess, onError) {
        return this.axiosGet('/category/list', body, onSuccess, onError);
      },
      getCategories(body, onSuccess, onError) {
        return this.axiosGet('/category', body, onSuccess, onError);
      },
      getCategory({ categoryId }, body, onSuccess, onError) {
        return this.axiosGet(`/category/${categoryId}`, body, onSuccess, onError);
      },
      createCategory(body, onSuccess, onError) {
        return this.axiosPost('/category', body, onSuccess, onError);
      },
      saveCategory({ categoryId }, body, onSuccess, onError) {
        return this.axiosPut(`/category/${categoryId}`, body, onSuccess, onError);
      },

      // Invoices
      getInvoices(body, onSuccess, onError) {
        return this.axiosGet('/invoice', body, onSuccess, onError);
      },
      filterInvoices(query, onSuccess, onError) {
        return this.axiosGet(`/invoices?${query}`, false, onSuccess, onError);
      },
      inputStore(body, onSuccess, onError) {
        return this.axiosPost('/invoices/input/store', body, onSuccess, onError);
      },
      outputStore(body, onSuccess, onError) {
        return this.axiosPost('/invoices/output/store', body, onSuccess, onError);
      },
      overheadOutputStore(body, onSuccess, onError) {
        return this.axiosPost('/invoices/overhead/output/store', body, onSuccess, onError);
      },
      overheadInputStore(body, onSuccess, onError) {
        return this.axiosPost('/invoices/overhead/input/store', body, onSuccess, onError);
      },
      getInvoiceDetail({ invoiceId }, body, onSuccess, onError) {
        return this.axiosGet(`/invoices/${invoiceId}`, body, onSuccess, onError);
      },

      // Measurement
      getMeasurements(body, onSuccess, onError) {
        return this.axiosGet('/measurements', body, onSuccess, onError);
      },
      getMeasurementList(body, onSuccess, onError) {
        return this.axiosGet('/measurements/list', body, onSuccess, onError);
      },
      getMeasurement({ measurementId }, body, onSuccess, onError) {
        return this.axiosGet(`/measurements/${measurementId}`, body, onSuccess, onError);
      },
      createMeasurement(body, onSuccess, onError) {
        return this.axiosPost('/measurements', body, onSuccess, onError);
      },
      saveMeasurement({ measurementId }, body, onSuccess, onError) {
        return this.axiosPut(`/measurements/${measurementId}`, body, onSuccess, onError);
      },
      deleteMeasurement({ Id }, onSuccess, onError) {
        return this.axiosDelete(`/measurements/${Id}`, null, onSuccess, onError);
      },

      // Attributes
      getAttributes(body, onSuccess, onError) {
        return this.axiosGet('/attribute', body, onSuccess, onError);
      },
      getAttributesList(body, onSuccess, onError) {
        return this.axiosGet('/attribute/list', body, onSuccess, onError);
      },
      getAttributeTypes(body, onSuccess, onError) {
        return this.axiosGet('/attribute/types', body, onSuccess, onError);
      },
      getAttribute({ attributeId }, body, onSuccess, onError) {
        return this.axiosGet(`/attribute/${attributeId}`, body, onSuccess, onError);
      },
      createAttribute(body, onSuccess, onError) {
        return this.axiosPost('/attribute', body, onSuccess, onError);
      },
      saveAttribute({ attributeId }, body, onSuccess, onError) {
        return this.axiosPut(`/attribute/${attributeId}`, body, onSuccess, onError);
      },

      // Reportable
      getWarehousesReport(body, onSuccess, onError) {
        return this.axiosGet('/storages', body, onSuccess, onError);
      },

      getBalance(query, onSuccess, onError) {
        return this.axiosGet(`/storages/balance${query}`, false, onSuccess, onError);
      },

      getDetails(query, onSuccess, onError) {
        return this.axiosGet(`/storages/attributes?${query}`, false, onSuccess, onError);
      },

      checkOverhead(overheadId, body, onSuccess, onError) {
        return this.axiosGet(`/invoices/overhead/${overheadId}`, false, onSuccess, onError);
      },

      getOverheads(query, onSuccess, onError) {
        return this.axiosGet(`/invoices/overhead?${query}`, false, onSuccess, onError);
      },

      getStorageProducts({ warehouseId }, query, onSuccess, onError) {
        return this.axiosGet(`/storages/products/${warehouseId}?${query}`, false, onSuccess, onError);
      },
      filterStorageProducts(query, onSuccess, onError) {
        return this.axiosGet(`/storages?${query}`, false, onSuccess, onError);
      },
    };
  },
};

const interceptor = api.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response;

  if (status === 401) {
    const accessToken = getters['user/accessToken'];
    const baseUrl = process.env.VUE_APP_BASE_URL;
    axios.interceptors.response.eject(interceptor);
    axios.post(`${baseUrl}/auth/refresh`, false, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(({ data }) => {
      dispatch('user/setToken', data);
      dispatch('user/setUser', data);

      return axios(error.response.config);
    }).catch(() => {
      dispatch('user/logout');
      router.push({ name: 'login' });
      return Promise.reject(error);
    }).finally(this);
  }

  if (status === 403) {
    router.push({ name: '403' });
  }

  if (status === 500) {
    Vue.prototype.$notification.warning({
      message: 'Серверная ошибка. КОД: 500',
    });
  }

  return Promise.reject(error);
});
