import Vue from 'vue';
import VueRouter from 'vue-router';
import { sync } from 'vuex-router-sync';
import store from '../store';
import Home from '../views/HomePage.vue';
import kernel from './kernel';
import auth from './middleware/auth';
import guest from './middleware/guest';

Vue.use(VueRouter);

export const routes = [
  {
    path: '',
    name: 'home',
    icon: 'home',
    component: Home,
    show: false,
    meta: {
      for: ['root', 'admin', 'owner', 'user', 'guest'],
      title: 'Главное',
      middleware: [auth],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
    meta: {
      for: ['root', 'admin', 'owner', 'user', 'guest'],
      title: 'Авторизация',
      middleware: [guest],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterPage.vue'),
    meta: {
      for: ['root', 'admin', 'owner', 'user'],
      middleware: [guest],
    },
  },
  {
    path: '/lists',
    icon: 'unordered-list',
    name: 'lists',
    show: true,
    component: () => import('../views/MainIndex.vue'),
    meta: {
      for: ['root', 'admin', 'owner', 'user'],
      title: 'Справочники',
      middleware: [auth],
    },
    children: [
      {
        path: '/users',
        icon: 'usergroup-add',
        name: 'users',
        show: true,
        component: () => import('../views/users/index.vue'),
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Пользователи',
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'users',
            show: true,
            component: () => import('../views/users/list.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Список',
              middleware: [auth],
            },
          },
          {
            path: 'create',
            name: 'users-create',
            show: true,
            drawer: true,
            drawerForm: 'UserCreate',
            component: () => import('../views/users/create.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Новый пользователь',
              middleware: [auth],
            },
          },
          {
            path: ':userId/edit',
            name: 'users-edit',
            show: false,
            component: () => import('../views/users/edit.vue'),
            meta: {
              title: 'Редактирование',
              for: ['root', 'admin', 'owner'],
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: '/products',
        icon: 'shopping',
        show: true,
        name: 'products',
        component: () => import('../layouts/PrimaryLayout.vue'),
        meta: {
          for: ['root', 'admin', 'owner', 'user'],
          title: 'Товары',
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'products',
            show: true,
            component: () => import('../views/products/list.vue'),
            meta: {
              for: ['root', 'admin', 'owner', 'user'],
              title: 'Список',
            },
          },
          {
            path: 'create',
            name: 'products-create',
            drawer: true,
            drawerForm: 'ProductCreate',
            show: true,
            component: () => import('../views/products/create.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Добавить товар',
              middleware: [auth],
            },
          },
          {
            path: ':productId/edit',
            name: 'products-edit',
            show: false,
            icon: 'product-edit',
            component: () => import('../views/products/edit.vue'),
            meta: {
              for: ['root', 'admin', 'owner', 'user'],
              title: 'Редактирование товара',
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: '/categories',
        icon: 'container',
        show: true,
        name: 'categories',
        component: () => import('../layouts/PrimaryLayout.vue'),
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Категории',
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'categories',
            show: true,
            component: () => import('../views/categories/list.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Список',
            },
          },
          {
            path: 'create',
            name: 'categories-create',
            show: true,
            drawer: true,
            drawerForm: 'CategoryCreate',
            component: () => import('../views/categories/create.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Новая категория',
              middleware: [auth],
            },
          },
          {
            path: ':categoryId/edit',
            name: 'categories-edit',
            show: false,
            component: () => import('../views/categories/edit.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Редактирование категории',
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: '/vendors',
        name: 'vendors',
        icon: 'global',
        component: () => import('../layouts/PrimaryLayout.vue'),
        show: true,
        meta: {
          for: ['root', 'admin', 'owner', 'user'],
          title: 'Поставщики',
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'vendors-list',
            show: true,
            component: () => import('../views/vendors/list.vue'),
            meta: {
              for: ['root', 'admin', 'owner', 'user'],
              title: 'Список',
            },
          },
          {
            path: 'create',
            name: 'vendors-create',
            show: true,
            drawer: true,
            drawerForm: 'VendorCreate',
            component: () => import('../views/vendors/create.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Новый поставщик',
            },
          },
          {
            path: ':vendorId/edit',
            name: 'vendors-edit',
            show: false,
            component: () => import('../views/vendors/edit.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Редактирование',
            },
          },
        ],
      },
      {
        path: '/measurements',
        name: 'measurements',
        icon: 'fork',
        component: () => import('../layouts/PrimaryLayout.vue'),
        show: true,
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Ед. измерения',
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'measurements-list',
            show: true,
            component: () => import('../views/measurements/list.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Список',
            },
          },
          {
            path: 'create',
            name: 'measurements-create',
            show: true,
            drawer: true,
            drawerForm: 'MeasurementCreate',
            component: () => import('../views/measurements/create.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Новая единица',
            },
          },
          {
            path: ':measurementId/edit',
            name: 'measurements-edit',
            show: false,
            component: () => import('../views/measurements/edit.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Редактирование',
            },
          },
        ],
      },
      {
        path: '/attributes',
        name: 'attributes',
        icon: 'build',
        component: () => import('../layouts/PrimaryLayout.vue'),
        show: true,
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Атрибуты',
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'attributes-list',
            show: true,
            component: () => import('../views/attributes/list.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Список',
            },
          },
          {
            path: 'create',
            name: 'attributes-create',
            show: true,
            drawer: true,
            drawerForm: 'AttributeCreate',
            component: () => import('../views/attributes/create.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Новый атрибут',
            },
          },
          {
            path: ':attributeId/edit',
            name: 'attributes-edit',
            show: false,
            component: () => import('../views/attributes/edit.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Редактирование',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    icon: 'carry-out',
    show: true,
    component: () => import('../views/MainIndex.vue'),
    meta: {
      for: ['root', 'admin', 'owner', 'user'],
      title: 'Отчёты',
      middleware: [auth],
    },
    children: [
      {
        path: '/warehouses',
        name: 'warehouses',
        icon: 'shop',
        component: () => import('../layouts/PrimaryLayout.vue'),
        show: true,
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Склады',
          middleware: [auth],
        },
        children: [
          {
            path: '',
            name: 'warehouses',
            show: true,
            component: () => import('../views/warehouses/list.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Список',
            },
          },
          {
            path: ':warehouseId/details',
            name: 'warehouses-details',
            show: false,
            component: () => import('../views/warehouses/details.vue'),
            meta: {
              title: 'Редактирование склада',
              for: ['root', 'admin', 'owner'],
              middleware: [auth],
            },
          },
          {
            path: 'create',
            name: 'warehouses-create',
            show: true,
            drawer: true,
            drawerForm: 'WarehouseCreate',
            component: () => import('../views/warehouses/create.vue'),
            meta: {
              for: ['root', 'admin', 'owner'],
              title: 'Новый склад',
              middleware: [auth],
            },
          },
          {
            path: ':warehouseId/edit',
            name: 'warehouses-edit',
            show: false,
            component: () => import('../views/warehouses/edit.vue'),
            meta: {
              title: 'Редактирование склада',
              for: ['root', 'admin', 'owner'],
              middleware: [auth],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/movements',
    icon: 'vertical-align-middle',
    name: 'movements',
    component: () => import('../views/MainIndex.vue'),
    show: true,
    meta: {
      for: ['root', 'admin', 'owner', 'user'],
      title: 'Движения',
      middleware: [auth],
    },
    children: [
      {
        path: 'incomes',
        name: 'invoices-income-list',
        icon: 'download',
        component: () => import('../views/invoices/income/index.vue'),
        show: true,
        meta: {
          for: ['root', 'admin', 'owner', 'user'],
          title: 'Приходы',
          middleware: [auth],
        },
      },
      {
        path: 'incomes/:invoiceId',
        name: 'income-details',
        show: false,
        component: () => import('../views/invoices/show.vue'),
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Детальный обзор прихода',
        },
      },
      {
        path: 'income',
        name: 'invoices-income',
        show: false,
        component: () => import('../views/invoices/income/income.vue'),
        meta: {
          for: ['root', 'admin', 'owner', 'user'],
          title: 'Приход',
        },
      },
      {
        path: 'expenses',
        name: 'invoices-expense-list',
        icon: 'upload',
        component: () => import('../views/invoices/expense/index.vue'),
        show: true,
        meta: {
          for: ['root', 'admin', 'owner', 'user'],
          title: 'Расходы',
          middleware: [auth],
        },
      },
      {
        path: 'expense/:invoiceId',
        name: 'expense-details',
        show: false,
        component: () => import('../views/invoices/show.vue'),
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Детальный обзор расхода',
        },
      },
      {
        path: 'expense',
        name: 'invoices-expense',
        show: false,
        component: () => import('../views/invoices/expense/expense.vue'),
        meta: {
          for: ['root', 'admin', 'owner', 'user'],
          title: 'Расход',
        },
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    icon: 'line-chart',
    component: () => import('../views/MainIndex.vue'),
    show: false,
    meta: {
      for: ['root', 'admin', 'owner'],
      title: 'Отчёты',
      middleware: [auth],
    },
    children: [
      {
        path: 'warehouses',
        name: 'warehouses-report',
        show: true,
        component: () => import('../views/reports/warehouses.vue'),
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Склады',
          middleware: [auth],
        },
      },
      {
        path: 'vendors',
        name: 'vendors-report',
        show: true,
        component: () => import('../views/reports/warehouses.vue'),
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Поставщики',
          middleware: [auth],
        },
      },
      {
        path: 'products',
        name: 'products-report',
        show: true,
        component: () => import('../views/reports/warehouses.vue'),
        meta: {
          for: ['root', 'admin', 'owner'],
          title: 'Товары',
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: '/access-denied',
    name: '403',
    component: () => import('../views/errors/403.vue'),
    meta: {
      for: ['root', 'admin', 'owner', 'guest'],
      title: 'Отказано в доступе',
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/errors/404.vue'),
    meta: {
      for: ['root', 'admin', 'owner', 'guest'],
      title: 'Страница не найдено',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

sync(store, router);

router.beforeEach((to, from, next) => {
  const { middleware } = to.meta;
  const user = store.getters['user/user'];

  if (typeof user === 'object' && !to.meta.for.includes(user.Role)) {
    return next({ name: '403' });
  }
  document.title = to.meta.title;

  if (!middleware) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: kernel(context, middleware, 1),
  });
});

export default router;
