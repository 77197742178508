import * as MUTATION from './mutation-types';

export default {
  [MUTATION.DRAWER_OPEN](state, { type, title }) {
    state.visible = true;
    state.type = type;
    state.title = title;
  },
  [MUTATION.DRAWER_CLOSE](state, payload) {
    if (payload === true) {
      state.type = null;
      state.title = null;
    }
    state.visible = false;
  },
};
